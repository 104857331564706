
.blogContentBtn{
    margin-top: 50px;
    width: 100%;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    color:white;
    padding: 10px;
    border: none;
    border-radius: 10px;
  
}
.blogContent p{
    color:black;
}
.BlgContent{
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
}
.Inputfield{
    width: 100%;
}
.blogContImg{
    width: 250px;
    height: 250px;
}
.blogContent{
    width: 100%;
    height: 95%;
    
    
}
.blgConright{
    display: flex;
    flex:40;  
    flex-direction: column;
}
.blgConleft{
    display: flex;
    flex:50;
    flex-direction: column;
     margin-top: 20px;
    margin-left: 20px;
}
.showBlog{
    display:flex;
    flex-direction: row;
    width: 100%;
   
}
.blogImage{
    width: 400px;
    height: 200px;
    border-radius: 20px;
  
}
.showBlogLeft{
    display: flex;
    flex: 50;
    flex-direction:column;
}
.showBlogRight{
    display: flex;
    flex: 50;
  
}
.blogtitles{
    margin-top: 30px;
   
    margin-right:30px ;
}
.iframe{
    width: 100%;
    height: 100vh;
      border: none;
      margin-top: 30px;
      font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}
