.Setcontainer{
    margin: 20px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.profileContent{
   display: flex;
   flex-direction: row; 
   width: 100%;
   /* background-color: red; */
}
.leftContainer{
    display: flex;
    flex:20;
    border-right: 1px solid rgba(0, 0, 0, 0.301) ;
    min-height: 100vh;
    width: 100%;
   
}
.leftContainer ul li{
    list-style-type: none;
    width: 100%;
}
.rightContainner{
    display: flex;
    flex: 80;
    width: 100%;
}
.setli{
    margin-top: 20px;
    cursor: pointer;
}
.setli img{
    padding: 10px;
}

.setliactive{
    color: black;
    font-weight: bold;
}
.setliactive img{
    padding: 10px;
}
.setliactive::after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    margin-right: -3px;
    border-radius: 8px;
    background-color: #F89C72;
}
.changpassContent{
    margin-left: 30px;
    width: 100%;
}
.Inputfield{
    width: 100%;
}
.textInputContent{
    width: 80%;
    display: flex;
    flex-direction: row;
}
.textInputleft{
    flex: 50;
    margin-right: 5px;
}
.textInputright{
    flex: 50;
    margin-left: 5px;
}
.onlineImg{
    width: 50%;
   display: flex;
   justify-content: flex-start;
}
.SaveBtnConte{
    width: 50%;
}