.Artist{
    overflow-x: scroll;
    
}
.container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.tabCnt1{
    display: flex;
    align-items: center;
    list-style-type: none;
    width: auto;
    white-space: nowrap;
}
.tab1{
    display: flex;
    font-weight: 700;
    font-size: medium;
    cursor: pointer;
    position: relative;
    color: black;
    margin: 30px 45px 0px 40px;
}
.tab1 p{
    font-weight: 500;
    font-size: small;
   
}
.tabActive1{
    color: #0E0101;
    width: auto;
    justify-content: flex-start;
   
}
.tabActive1::after{
    content: '';
    height: 3px;
    width: 110%;
    border-radius: 10px;
    background-color: #ef643ecd;
    position: absolute;
    bottom: 10px;
    left: 50%;
     margin-bottom: -17px;
    transform: translate(-50%,50%);
}
.FQAdisp{
    font-weight: bold;
}
.Artist1{
    width: 100% !important;
    
}
