.Artist {
    overflow-x: scroll;
}

.tabCnt1 {
    display: flex;
    align-items: center;
    list-style-type: none;
    width: auto;
    white-space: nowrap;
}

.tab1 {
    display: flex;
    font-weight: 700;
    font-size: medium;
    cursor: pointer;
    position: relative;
    color: black;
    margin: 30px 45px 0px 40px;
}

.tab1 p {
    font-weight: 500;
    font-size: small;
    padding: 5px 0px 0px 5px;
}

.tabActive1 {
    color: #0e0101;
    width: auto;
    justify-content: flex-start;
}

.tabActive1::after {
    content: '';
    height: 3px;
    width: 110%;
    border-radius: 10px;
    background-color: #EF633E;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 50%);
}
.groupIcon{
    background-color: #EF633E;
   border-radius: 100%;
    color: white;
    margin-right: 10px;
    margin-left: 30px;
    padding: 5px;
}   
.groupInfo{
    text-transform: capitalize;
    text-align: left;
   
}
.tableTr td{
text-align: left;
}