.InputBox {
    margin-bottom: 35px;

}
.InputBox label{
   color:  #B5B5BE;

}


/* button */

.btn{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    color: #FFFFFF !important;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn1 {
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
    margin-top: 35px;
    margin-bottom: 25px;
}
.btn2 {
    background:  #EA4335 0% 0% no-repeat padding-box;
    margin-top: 25px;
}

.btn3 {
    background: #3C5A99 0% 0% no-repeat padding-box;
    margin-bottom: 15px;
    margin-top: 15px;
}
.btn3>img, .btn2>img, .btn4>img{
    margin-right: 10px;
}

.btn4 {
    background: transparent linear-gradient(180deg, #C01C9C 0%, #FF2470 100%) 0% 0% no-repeat padding-box;
}

p {
    letter-spacing: 0.1px;
    color: #B5B5BE;
    opacity: 1;
}
/* SignUp top nav */
.tabcontainer{
    width:auto;
    height: auto;

  
   
}
.coachbutton{
    width: 50%;
    border: none;
    outline: none !important;
 color: black;
    
}  
.buttonContainer Button{
    width: 50%;
    border: none;
    outline: none !important;
    color: black !important;
}

.coachbutton{
 color:black;
border-bottom: solid 2px  #97CC4F;
}  
