.imagediv{
   width: 190px;
   height: 190px;
   top: 406px;
   left: 869px;
    opacity: 1;
    margin-bottom: 25px;
    margin-top: 25px;
}
.inputfield{
    opacity:1;
    background: transparent url('/public/images/svg/Coach/copyclick.svg') 97% 50% no-repeat padding-box;
    margin-top: 45px;
    margin-left: 40px;
    margin-right: 40px;
    padding-left:15px ;
    padding-top: 5px;
    padding-right: 20px;
    top: 696px;
    left: 800px;
    width: 450px;
    height: 48px;
    border-radius: 10px;
    border:1px solid #E2E2EA;
    font-size: small;

    
    
}

.sharediv{
    margin-top: 20px;
    margin-bottom: 25px;
    margin-left: 160px;
    top: 771px;
    /* left: 950px;
    width: 250px;
    height: 25px; */
    text-align: center;
    font: normal normal normal 15px/25px Poppins;
    letter-spacing: 0.06px;
    color: #B5B5BE;
    opacity: 1;
}
.button{
top: 844px;
left: 717px;
width: 486px;
height: 50px;
background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
border-radius: 10px;
opacity: 1;
text-align: center;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
margin-top: 38px;
border: none;
}
    .profilediv{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    align-items: center;
    }