
.container input{
    border: 1px #97CC4F solid;
     height: 2.8em;
     width: 2.8em;
    border-radius: 8px;
    text-align: center;
    margin: 7px 5px 20px 7px;
}


.container{
    display:flex;
    justify-content: center;
    margin-bottom: 15px;
}
.code{
    font-size: small;
    /* margin-bottom: 35px; */
    letter-spacing: 0.11px;
    opacity: 1;
     cursor: pointer
    ;

}
.code button{
    font-weight: bold;
    background-color: white;
    border:0;
    outline:0;
    cursor: pointer;
   
}
.code button:hover{
    cursor: pointer;
    text-decoration: underline;
}
.createid{
    margin-top: 30px;
    border: none;
    color: aliceblue;
    background:transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box ;
    opacity: 1;
    border-radius: 8px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: box-shadow.3s ease-out;
}


