.maincontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 800px;
  position: relative;
  top: 10px;
  left: 5px;
}

.row {
  opacity: 0.8;
  color: #b5b5be;
}

.Heading {
  font-size: 20px;
  font-weight: bolder;
  justify-content: flex-start;
  color: black;
  margin-bottom: 25px;
  font-weight: 900;
}
.muiInput {
  border-radius: 10px;
}
.maincontainer2 {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 800px;
  gap: 5px;
  row-gap: 2px;
  position: relative;
  top: 10px;
  left: 10px;
}

.maincontainer3 {
  display: flex;
  height: auto;
  width: 600px;
  position: relative;
  top: 10px;
  left: 10px;
}

#spacedede {
  justify-content: space-evenly;
}

.inputt {
  width: 367px;
  height: 48px;
  margin-bottom: 17px;
  padding-right: 100px;
  margin-left: 18.5px;
  border-radius: 10px;
  padding-left: 18px;
  color: rgba(0, 0, 0, 0.927);
}

.inputt1 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt2 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt3 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt4 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt5 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt6 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt7 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt8 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt9 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt10 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt11 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
  background: transparent
    url("../../../public/images/svg/Coach/group-68408.svg") 96% 41% no-repeat
    padding-box;
  opacity: 1;
}

.inputt12 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt13 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt14 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea;
}

.inputt15 {
  margin-left: 18.5px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 367px;
  height: 48px;

  border: 1px solid #e2e2ea;
}

.ImgVideoContent {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.image-container {
  text-align: center;
}

.image {
  height: 180px;
  width: 180px;
}

.textcenter1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-field {
  border-radius: 20px;
  margin: 10px;
}

.field {
  width: 500px;
}

.signupform {
  max-width: 800px;
  width: 100%;
  padding: 10px;
}

.field-area {
  padding: 20px;
  border-radius: 10px;
}

.field input {
  padding: 10px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid rgba(27, 20, 20, 0.452);
  width: 100%;
}

.field input[type="text"] {
  outline: none;
  border: 1px solid rgba(34, 87, 148, 0.452);
}

.field input:active {
  outline: none;
}

.leftalign {
  text-align: left;
}

.logo {
  z-index: 2;
  position: absolute;
  margin-left: 125px;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-top: -85px;
}

.maincontainer1 {
  display: flex;
  height: 400px;
  width: 500px;
  position: relative;
}

.zero {
  width: 142px;
  height: 17px;
  color: black;
  margin-right: 200px;
  font: normal normal medium 14px/17px Roboto;
  margin-left: 5px;
  font-weight: bold;
}

.one {
  width: 142px;
  height: 17px;
  color: black;
  font: normal normal medium 14px/17px Roboto;
  font-weight: bold;
  margin-left: -15px;
  margin-right: 5px;
}

.text1 {
  margin-left: 16px;
}

.option {
  display: flex;
  flex: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.options {
  margin-left: -10px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 5px;
}

.options1 {
  margin-left: 20px;
  margin-top: 11px;
  margin-bottom: 25px;
  margin-right: 10px;
}

.options2 {
  margin-left: 240px;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 0px;
}

.options3 {
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.textcenter2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textcenter3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textcenter4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 80%;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 10px;
  border: none;
  padding: 10px 10px;
  color: white;
  margin: 10px;
}
.button5 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 450px;
  background-color: #7aa146;
  border-radius: 10px;
  border: none;
  padding: 10px 10px;
  margin-left: -15px;
  color: white;
}
.button6 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 450px;
  background-color: #7aa146;
  border-radius: 10px;
  border: none;
  padding: 10px 10px;
  margin-left: -13px;
  margin-top: 15px;
  color: white;
}
.button7 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 450px;
  background-color: #7aa146;
  border-radius: 10px;
  border: none;
  padding: 10px 10px;
  margin-left: -3px;
  color: white;
}

.button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 506px;
  height: 50px;
  margin-top: 5px;
  margin-left: 77px;
  background: transparent 0% 0% no-repeat padding-box;
  border: none;
}

.input01 {
  width: 753px;
  height: 96px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.input02 {
  width: 753px;
  height: 96px;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.input1 {
  width: 350px;
  height: 48px;
  margin-top: -5px;
  margin-bottom: 10px;
  padding-right: 90px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  padding-left: 18px;
  border: 5px solid #e2e2ea9a;
}

.input2 {
  margin-left: 15px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  margin-top: -5px;
  width: 367px;
  height: 48px;
  border: 1px solid #e2e2ea9a;
}

.input3 {
  margin-left: 5px;
  margin-top: 2px;
  border-radius: 10px;
  margin-bottom: 17px;
  padding-left: 18px;
  padding-right: 100px;
  width: 350px;
  height: 48px;
  border: 1px solid #e2e2ea9a;
  margin-right: 395px;
}
.optioninput2 {
  float: right;
  margin-left: 105px;
  margin-right: 250px;
  margin-top: -25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 142px;
  height: 17px;
  font-weight: bold;
  color: black;
}

.optioninput2 input {
  width: 25px;
  height: 25px;
}
.optioninput1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  width: 147px;
  height: 17px;
  margin-right: 200px;
  margin-left: 2px;
  font-weight: bold;
  color: black;
  margin-top: 50px;
  margin-bottom: 10px;
}
.optioninput1 input {
  width: 25px;
  height: 25px;
}
.button2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 506px;
  height: 50px;
  margin-top: 5px;
  margin-left: 95px;
  background: transparent 0% 0% no-repeat padding-box;
  border: none;
}

.button3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 506px;
  height: 50px;
  margin-top: 5px;
  margin-left: 83px;
  background: transparent 0% 0% no-repeat padding-box;
  border: none;
}

/*                           Coachee Tabs  */
.FormContainer {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.InputContainer {
  display: flex;
  justify-content: space-between;
  width: auto;
}
.muiInput {
  border-radius: 10px !important;
  width: 100%;
}
.Inputfield {
  width: auto;
  position: relative;
}
.ProfileImage {
  display: flex;
  flex-direction: column;
  position: relative;

  margin-bottom: 20px;
  cursor: pointer;
}
.ProfileImage p {
  margin-bottom: -5px;
}

.MainImg {
  width: 180px;
  height: 180px;
  margin: 10px;
  border-radius: 10px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainProfile {
  width: 178px;
  height: 178px;
  border-radius: 10px;
}

.MainImgPre {
  display: flex;
  color: grey;
  width:100%;
  height:100%;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.IconImg {
  position: absolute;
  margin-left: 130px;
  margin-bottom: -10px;
  bottom: 0;
}

.FormButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FormButton1 {
  color: #97cc4f;
  background-color: white;
  outline: none;
  border: none;
}
.CoacheeFormContent {
  display: flex;
  flex-direction: column;
}
/*               CP -Tap 1 */
.mainContainer {
  width: auto;
}
.inputContent {
  width: auto;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}
.inputContent1 {
  width: 70%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}
.LeftInputField {
  display: flex;
  width: 100%;
  
  flex-direction: column;
}

.RightInputField {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.inputFieldContent {
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.InputDataContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin-top: 20px;
}
.multiFmIcon {
  position: absolute;
  float: right;
  right: 0;
  top: 0;
  margin-top: -10px;
  margin-right: -10px;
  padding: 2px;
  color: white;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
}
.leftInputData {
  width: auto;
  display: flex;
  flex-direction: row;
}
.inputFieldContent .Inputfield {
  width: 100%;
}
.Inputfield1 {
  width: 50%;
}
.RightInputData {
  width: auto;
  display: flex;
  flex-direction: row;
}
.Addbtn {
  display: flex;
  justify-content: center;
}

.Addbtn .AddbtnContainer {
  margin: 20px;
  font-size: 30px;
  color: white;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
}
.multiFormCols {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.multiRows {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.multiFormContent {
  width: auto;
}
/* Cp tab 2 */
.multiRows .Inputfield {
  width: 100%;
}
.multiFmBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.choicetag {
  display: flex;
}
.choicetag label {
  margin: 10px;
}
.leftFormData {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.YesNo {
  margin: 5px;
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.DateOf {
  position: absolute;
  font-size: 13px;
  color: #1c74bc;
  margin-bottom: -15px;
  margin-left: 13px;
  background-color: white;
  z-index: 10;
}
.CoachQuality {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.TextAreaField {
  margin: 10px 10px 20px 0px;
}

.ProfileVideo {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.MainVideo {
  height: auto;
  width: auto;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.MainImgPre1 {
  width: 190px;
  height: 190px;
  border-radius: 20px;
}
.MainVideo img {
  width: 250px;
  height: 150px;
  border-radius: 20px;
}
.MainVideoIcon {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.MainVideo video {
  width: 250px;
  height: 150px;
  border-radius: 20px;
}
.IconVideo {
  position: absolute;
  margin-left: 200px;
  margin-bottom: -10px;
  bottom: 0;
}
@media screen and (max-width:700px) {
  .inputContent {
  width: auto;
  display: flex;
  flex-direction: column;

 justify-items: center;
 align-items: center;
}
.ImgVideoContent{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LeftInputField{
 
  width: 90%;
 
}
.RightInputField{
 
  width: 90%;
 
}
}
@media screen and (max-width:400px) {
  .LeftInputField{
 
    width: 60%;
   
  }
  .RightInputField{
   
    width: 60%;
   
  }
}