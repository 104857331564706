.modalBox{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalInner{
    width: 80%;
    min-height: 100px;
    border-radius: 25px;
    box-shadow: 0 0 10px -2px rgba(141, 141, 141, 0.2);
    background-color: white;
    max-width: 400px;
    overflow: hidden;
  }
  .modalHeader{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #3c5a99;
  }
  .modalHeader p{
    color: whitesmoke;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
  .modalContent{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 20px;
    color: #43464b;
    max-width: 80%;
    font-size: 16px;
  }
  .modalBtnCnt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 20px;
    border-top: 1px solid gainsboro;
  }
  .modalBtn{
    width: 80px;
    height: 45px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #3c5a99;
    color: #3c5a99;
    background: white;
    margin: 0 0 0 5px;
    
  }
  .modalBtn:hover{
    background: rgb(241, 241, 241);
  }
  .modalBtn:active{
    opacity: 0.7;
  }