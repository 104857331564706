.container{
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bkTop{
    position: absolute;
    height: 430px;
    /* height: 60%; */
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to top right, #0b3467, #1a5298);
    transform: skewY(3deg)translateY(-13%);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}
.bkTopInner{
    position: absolute;
    width: 100%;
    transform: rotate(-3deg)translate(25%,-80%);
}

.personJump{
    position: absolute;
    right: 5%;
    bottom: 0;
    height: 50%;
    transform: translateY(28%);
}
.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    font-size: 8px;
    text-align: center;
    padding: 10px 0;
    color: #333333;
}

.mainContainer{
    height: auto;
    min-width: 500px;
    z-index: 1;
    padding: 15px;
    margin: 100px 0;
}
.mainContainerInner{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 20px;
    width: 100%;
    height: auto;
    padding: 40px;
}
.heading{
    color: white;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 10px 0;
}
.message{
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    color: gainsboro;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 20px 0;
}

@media screen and (min-width:800px){
    .mainContainerInner{
        width: auto;
    }
}