.container{
    padding:30px;
    height: auto; 
 background-image: url("../../../../../public/images/Coach/faqbg.png") ;
 width: 100%;
 background-repeat: no-repeat;
 background-position: right bottom;
   background-size: 300px 300px;
   display: flex;
   flex-direction: column;
   min-height: 100vh;
   
}
.IndexContainer{
     width: 100%;
    display: flex;
    justify-content: space-between ;
    padding:30px;
   
}
.headercontainer{
    display: flex;
    justify-content: space-between;
}
.headname{
    font-weight: bolder;
    font-size:20px;
}
.linkname
{
    font-size: 10px;
    font-weight: bold;
    color: #1C74BC;
    cursor: pointer;
}
.linkname span{
    margin-left:7px ;
}
.accordioncontainer{
    padding: 20px;
    display: flex;
    flex-direction: column;
  
    width: 80%;
}
.accordioncontainer Accordion{
   box-shadow: 0px 6px 10px  #00000008;
border: 1px solid #E5EAF4;
color: black;

}
.accordion{
    font-weight: bold;
}
.datacontent{
    margin-bottom: 20px;
}
.FQAdisp{
    font-weight: bolder;
}
.FaqContainer{
    width: 100%;
}
.SupportContent{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.BackTo{
    color:#1C74BC;
    cursor: pointer;
}