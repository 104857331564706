.ProContainer {
 min-width: 100%;
 min-height: 100vh;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  overflow-y: scroll;
}

.CardContainer {
  display: flex;
  gap: 15px;
}

.SingleCard {
  position: relative;
  height: 15rem;
  border-radius: 25px;
  width: 15rem;
  display: flex;
  margin: 20px;
  justify-content: center;
}
.container2 {
  height: auto;
  width: 33%;
  border-radius: 10px;
  padding: 10px 10px 10px 45px;
  background: url(../../public/images/svg/Coach/search.svg) 5% 57%
      no-repeat padding-box,
    url(../../public/images/svg/Coach/image1.svg) 94% 57% no-repeat
      padding-box;
  background-size: 15px 15px;
  margin-bottom: 30px;
}
.DetailContainer {
  position: absolute;
  bottom: 20px;
  width: 90%;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px #0000000d;
 margin-bottom: -80px;
 cursor: pointer;
}

.ProImage {
  height: 200px;
  width: 250px;
  border-radius: 25px 25px 0 0;
}
