.InputText1 input {
    width: 100%;
    height: 50px;
    border: 1px solid #E2E2EA;
    border-radius: 10px;
    margin: 10px 0px 10px 0px;
    text-indent: 20px;
}
.btn {
    width: 100%;
    height: 50px;
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin: 20px 0px 0px 0px;
    border: 0px;
    color: #FFFFFF;
}