.mailText {
    color: #B5B5BE;
}
.InputBox {
        width: 100%;
        border: 0;
        border-bottom: 1px solid rgb(201, 193, 193) !important;
        outline: 0;
        font-size: 1.3rem;
        color: #44444F;
        background: transparent url('/public/images/Coach/mail-icon.png') 97% 52% no-repeat padding-box;
        opacity: 1;
        margin-bottom: 20px;
}
.btn {
    width: 100%;
    height: 50px;
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #FFFFFF;
    border: 0px;
}