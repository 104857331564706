/* AS-tab1 */
.container {
  margin: 20px 30px 30px 30px;
  white-space: nowrap;
  overflow: scroll;
}

.container2 {
  height: auto;
  width: 30%;
  border-radius: 10px;
  padding: 10px 10px 10px 45px;
  background: url(../../../../../public/images/svg/Coach/search.svg) 5% 57%
      no-repeat padding-box;
  background-size: 15px 15px;
  margin-bottom: 30px;
}

/* coachImg */
/* AS-tab3 */
.button {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #7aa146;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px;
}

.button1 {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #f89c72;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px;
}

.button2 {
  height: 33px;
  width: 33px;
  background-color: #0b3467;
  border-radius: 7px;
}
.sessiontableTD{
  display: flex;
  align-items: flex-start;
}
.button3 {
  height: 33px;
  width: 33px;
  background-color: #ff2222;
  border-radius: 7px;
}

/* AS-tab-4 */
.buttonn {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #7aa146;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 5px;
}

.coachImg {
  border-radius: 100%;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.coachImg2 {
  border-radius: 100%;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.sessiontable td {
  align-items: center !important;
  width: auto;
  justify-content: flex-start;

  text-align: center;
}

.showinfoConte {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  justify-content: flex-start;
  text-align: left;
}

.showCInfo {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.showCinfoImg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 25px;
}

.showCinofProg {
  color: #eb7037;
  font-size: 13px;
}

.showCinofP {
  color: black;
}

.sessiontable td img {
  margin-left: 5px;
}

.alignCenter {
  text-align: center !important;
}

.upcomeAppContent {
  display: flex;
  
  max-width: 400px  ;
}

.upcomeAppContent ul {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.AccStatus0 {
  text-align: left;
  color: rgb(228, 184, 25);
}

.AccStatus1 {
  text-align: left;
  color: rgb(205, 205, 52);
}

.topBtn3 {
  background-color: #7aa146;
  color: white;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
  margin-right: 20px;
  outline: none;
}

.AccStatus2 {
  text-align: left;
  color: #7aa146;
}

.AccStatus3 {
  text-align: left;
  color: #ff9822;
}

.AccStatus4 {
  text-align: left;
  color: #ff2222;
}

.StartSessBtn {
  background-color: #7aa146;
  margin-right: 10px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: white;
}

.SendMsg {
  background-color: #ec7e4b;
  margin-right: 10px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: white;
}

.showDetailsCoach {
  display: grid;
  grid-template-columns: repeat(4,  1fr);
}

.showDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
 
  
}

.showDetails p {
  color: #0b3467;
  font-size: 13px;
  font-weight: light;
  margin-bottom: -1px;
}

.showDetails h6 {
  color: #0b3467;
  margin-bottom: 10px;
  max-width: 200px;
  overflow: scroll;
 
}

.showCertificates {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  justify-content: left;

  align-items: left;
  text-align: left;
}

.certicontent {
  display: grid;
  grid-template-columns: repeat(4,4fr);
  align-items: center;
  
}
.certicontent div {
  padding: 5px;
}
.certicontent p {
  color: #0b3467;
  font-size: 13px;
  font-weight: light;
  margin-bottom: -1px;
}

.certicontent h5 {
  color: #fff;
  margin: 10px;
  background-color: #1A5298;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width:1500px){
  .showDetailsCoach{
    grid-template-columns: repeat(3,  1fr);
  }
}
@media screen and (max-width:1000px){
  .showDetailsCoach{
    grid-template-columns: repeat(2,  1fr);
  }
}