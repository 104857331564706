.programsDisplay{
    margin: 20px;
    width: 100%;
}
.programHead{
    width: 100%;
align-items: center;
    display: flex;
    flex-direction: row;
    
   
}
.progImg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
}
.programHead p{
    color:#0B3467;
}
.groupIc{
    background-color: #EF633E;
    border-radius: 50%;
    padding: 6px 8px;
    color: white;
    margin-right: 10px;
}
.groupIcTd{
    text-align: left;
    margin-left: 20px;
    text-transform: capitalize;
}
.groupUpcome{
    color: #EF633E;
}
.groupSession{
    padding: 5px;
    border-radius: 50%;
    color: white;
    margin-right: 10px;
    background-color: #EF633E;
    text-transform: uppercase;
}
.groupComplete{
    color: #0C5899;
}
.totalSessHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.totalSessHeadBtn{
    background-image: linear-gradient(
        to bottom,
        #97cc4f,
        #90c14d,
        #88b64b,
        #81ac48,
        #7aa146
      ) !important;
      padding: 10px;
      border: none;
      border-radius: 10px;
      color: white;
      outline: none;
      margin-bottom: 20px;
}
button:active { 
    transform: scale(0.9); 
} 
.profileImg{
    width: 70px;
    height: 70px;
    border-radius: 50%;
   
    margin-right: -20px;
    border: 1px solid white;
    background-color: white;
    border-radius: 100%;
}
.menuDesign{
    width: 100%;
}
.cardDesignSelect{
    width: 100%;
}
@media screen and (max-width:800px) {
    .totalSessHead{
        flex-direction: column;
    }
    .totalSessHeadBtn{
        width: 50%;
    }
}