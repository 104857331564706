.ChatContainer{
    margin: 20px;
    width: 100%;
}
.Chatparts{
    width: 100%;
    display:flex;
    flex-direction: row;
}
.ChatpartsLeft{
    width: 100%;
  max-height: 70vh;
  height: 70vh;
  overflow-y: scroll;
    display: flex;
    flex: 30;
  flex-direction: column;
}
.ChatpartsRight{
    min-height: 50vh;
    height: auto;
        width: 100%;
        min-width: 250px;
       display: flex;
       flex: 70;
      justify-content:space-between;
    flex-direction: column;
    margin-left: 10px;
    
}
.Chathead{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Chathead h4{
    font-weight: bold;
}
.Chathead p{
    color: #3a80d5;
    cursor: pointer;
}
.Chathead button{
       background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
 border: none;
 border-radius: 10px;
 padding: 0px 10px 0px 10px ;
 color: white;
 outline: none;
}
.UpgradeDiv{
 
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.typoDesc{
    width: auto;
  min-width: 300px;
  padding: 10px;
}
.BoxStyle{
     position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: white;

  box-shadow: 24;
  padding:20px 20px 20px 20px ;
  display:flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}

.HandleCloseIcon{
    font-size: 40px !important;
    padding:4px;
     background-image: linear-gradient(to bottom, #174C8E, #184E92, #154888, #13437F, #174C8E) ;
    border: 4px solid white;
    color: white;
    border-radius: 100%;
}
.BoxStyle span{
    cursor: pointer;
 position: absolute;
 margin-top: -10px;
 margin-right: -20px;
    outline: none;
    border: none;
     width: 10%;
  top:0;
  float: right;
  right: 0;
 
}
.Inputfield{
    width: 100%;
}
.InputTextfield{
    width: 100%;
    margin-left: 2px;
}
.TypoButton{
    width: 100%;
     font-weight: bold;
text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
  border-radius: 10px !important;
  border:none;
  color: white !important;
  padding:10px;
  margin-bottom: 10px;
  outline: none ;
text-align: center;
cursor: pointer;
}
.TicketDesc{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
   box-shadow: 0px 7px 24px #00000014;
   /* background-color: #00000014; */
   border-radius: 20px;
   padding: 5px;
   cursor: pointer;
}
.TicketDesc1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
   box-shadow: 0px 7px 24px #00000014;
   background-color: rgba(135, 207, 235, 0.253);
   border-radius: 20px;
   padding: 5px;
   cursor: pointer;
}
.doNotDistb{
    color:red;
    margin-right: 20px;
    margin-top: 20px;
}
.TicketDesc p{
    color: black;
}
.TicketDesc span{
    color: rgba(0, 0, 0, 0.378);
    font-size: 15px;
}
.SendMsgButton{
    display: flex;
    flex-direction: row;
    width:100% auto;
    justify-content: space-between;
    border: 2px solid rgba(0, 0, 0, 0.373);
    border-radius: 10px;
   
}
.ChatPage{
    width: 100%;
}
.DisplayMsg{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

}
.chatingSection{

    height: 70vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    /* justify-content: end; */
}
.chatingSection1{

    height: 70vh;
    overflow: scroll !important;
    justify-content: end;
    display: flex;
    flex-direction: column;
  
    margin-left: 10px;
}

/* .DisplayMsg p{
    color: black;
   
    display: flex;
    justify-content: space-between;
    width: 100%;
} */
.SendMsgButton button{
    background-color: white;
    border: none;
    color: #81ac48;
outline: none;
padding: 10px 0px 10px 0px;

}
.SendMsgButton input{
    width: 96% auto;
    border: none;
    margin-left: 3px;
}
.SendMsgButton input:focus{
   
    border: none;
    outline: none;
}

.msgTextLeft{
    display: flex;
   flex-direction: row;
   align-items: center;
    justify-content: flex-end;
    right:0;
}
.msgTextLeft p{
    width: fit-content;
    max-width: 400px;
    background-color: rgba(128, 128, 128, 0.195);
    padding: 10px;
    border-radius: 30px;
    color: black;
}
.msgTextRight{
    display: flex;
   flex-direction: row;
    justify-content: flex-start;
    align-items: center;
   
    right:0;
}
.msgTextLeft span{
    font-size: 13px;
    color: gray;
    margin: 5px;
}
.msgTextRight p{
    width: fit-content;
    max-width: 400px;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid gray;
    color: black;
}
.msgTextRight span{
    font-size: 13px;
    color: gray;
    margin: 5px;
}

@media screen and (max-width:800px) {
    .Chatparts{
        display: flex;
        flex-direction: column;
       
    }

    .ChatpartsRight{
        flex: 90;
        overflow: scroll;
        justify-content: start;
        
        
    }
    .ChatpartsLeft{
        max-width: 480px;
     max-height: 100px;
      overflow-x: scroll;
        display: flex;
        flex: 10;
      flex-direction: row;
      
   

    }
    .SendMsgButton{
        justify-content: space-around;
    }
    .DisplayMsg{
        margin-right: 30px;
    }
    
}
@media screen and (max-width:800px) {
    .DisplayMsg{
        margin-right: 30px;
    }
}