.container {
    min-height: 100vh;
    height: auto;
    width: 100%;
   
    background-color: #0394b80f;
}

.whitebg {
    height: auto;
    width: 80%;
}

.foreGround {
    height: auto;
    width: 100%;
    z-index: 1;
    position: relative;
    padding-bottom: 100px;
}

.BodyContent {
    z-index: 0;
}

.bkTop {
    position: absolute;
    z-index: 0;
    
    height: 900px !important;
    /* max-height: 160vh; */
  
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to top right, #0b3467, #1a5298);
    transform: translateY(-13%);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    overflow: hidden;
}

.bkTopInner {
    position: absolute;
    width: 100%;
    transform: rotate(-3deg)translate(35%, -57%);
}

.navbar {
   display: flex;
   flex-direction: row;
    margin: 40px;
    justify-content: space-between;
}
.navbarImg {
    width: 180px;

}
.navbar ul {
    
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.navbar ul li{
   
}
.letsBtn {
    margin: 20px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    color: white;
    width: 190px;
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
}
.HamburgderContent{
    display: none;
}
.HamburgderContent button{
    background-color: transparent;
    border: none;
    color: white;

}
.hamburger nav{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100px;
    padding: 10px;
    border-radius: 10px;
}
.hamburger nav a{
    color: #0a3f7f;
    cursor: pointer;
}
.TopNavContent {
   
    width: 50%;
    display: flex;
    color: grey;
    margin: 20px;
    justify-content: space-between;
    cursor: pointer;
}

.tabActive1 {
    color: white
}

.Activate1 {
    color: #7AA146;
    margin-left: 10px;
}

/*                    Home Styling                     */
.headerContainer {
    color: white;
    margin: 40px;
    display: flex;
    justify-content: center;

}

.DSHead {
    color: #97CC4F;
}

.headerTxt {
    width: 60%;
    text-align: center;
    font-size: 40px;
   
  
    font-weight: 600;

}
.headerTxt1 {
    width: 60%;
    text-align: center;
    font-size: 33px;
   
  
    font-weight: 600;

}

.headerpara {

    display: flex;
    justify-content: center;
}

.headerpara p {
    text-align: center;
    width: 53%;
    font-size: 12px;
}

.headButton {
    display: flex;
    justify-content: center;
    

}
.signUpButton,
.startNowButton {
   margin: 10px;
}
.signUpButton{
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid #97CC4F ;
    padding: 10px 20px;
    color:#97CC4F
}
.letsBtn1{
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
   border-radius: 10px; 
}

.startNowButton {
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
    padding: 10px;
    border-radius: 10px;
    color: white;
    border: none;
    padding: 10px 20px;
    /* margin-right: 30px; */
    /* width: 12%; */

}



.activeImgContentUpper {
    display: flex;
    justify-content: center;

}

.activeImgContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeImgContent img {
    width: 70%;
}

.navBar {

    display: flex;
    align-items: center;
    justify-content: center;

}

.navBar ul {
    margin-top: 30px;
    width: 70%;
    display: flex;
    list-style-type: none;
    color: white;
    justify-content: space-between;
}

.navBar ul li {
    cursor: pointer;
}

.dashIcon {
    margin-right: 10px;
}

.navBar ul li {
    margin-bottom: 30px;
}

.UpcomingSection {

    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.UpcomingSection1 {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #0c579914;
}

.UpComingSect {
    margin-top: 5%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.LeftUpcome img {
    width: 80%;
    height: 80%;
    background-color: #0c579914;
    border-radius: 20px;

    padding-bottom: 20px;
}

.LeftUpcome1 img {
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.LeftUpcome1 {
    flex: 60;
    width: 100%;
}

.LeftUpcome {

    flex: 60;
    width: 100%;
}

.RightUpcome {
    flex: 40;
}

.DsbsRBlue {
    font-weight: bold;
}

.DsbsBlue {
    color: #0C5899;
}

.RightUpcome p {
    color: rgba(0, 0, 0, 0.608) !important;
}

.OfferContent {

    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}
.contactImg1{
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px;
    rotate: calc(0deg) !important;
}
.OfferContent h2 {
    font-weight: bold;
    text-align: flex-start;
}

.OfferPara {
    width: 30%;
    color: rgba(0, 0, 0, 0.608) !important;

}

.CardContent {
    width: 70%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;

}

.cardsMasterContent {
    width: 70%;
    display: flex;

    align-items: center;
    justify-content: center;
}

.CardContent1 {
    width: 70%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(4, 1fr);

    margin-right: 10%;
}


.CardDesign1 {
    box-shadow: 4px 8px 18px #00000024;
    margin: 10px;
    border-radius: 20px;
    display: flex;
    /* width: 100px; */
    flex-direction: column;
    padding: 10px;
    font-size: 13px;
    height: 350px;
    width: 300px;
    justify-content: space-evenly;

}

.CardDesignBlog {
    box-shadow: 4px 8px 18px #00000024;
    margin: 10px;
    border-radius: 20px;
    display: flex;
    width: 270px;
    flex-direction: column;
    padding: 10px;
    font-size: 13px;
    height: 400px;
    justify-content: space-evenly;

}

.CardDesign2 {
    box-shadow: 4px 8px 18px #00000024;
    margin: 10px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 13px;
}

.OfferICon {
    width: 40px;
    padding: 8px;
    border-radius: 30%;
    background: #0b3467 50% 10% no-repeat padding-box;
    margin-top: 10px;
    margin-bottom: 10px;
}

.OfferICon1 {
    width: 50px;
   height: 50px;
}

[data-id="Card1"] {
    transform: rotate(-1deg);
}

[data-id="Card2"] {
    transform: rotate(1deg);
}

.WhatContent {
    padding-top: 5%;
    background: #4e87d617 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WhatInnerContent {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.WhatInnerp {
    color: #0C5899;
    font-weight: bold;
}

.IndexNumber {
    background: transparent linear-gradient(180deg, #0b3467 0%, #0a3f7f 100%) 0% 0% no-repeat padding-box;
    width: 40px;
    border-radius: 100%;
    padding: 5px 10px 5px 13px;
    color: white;
}

.WhatFeature {
    display: flex;
    flex-direction: column;

    align-items: center;
}

.WhatFeatureContent {
    display: flex;
    justify-content: space-between;
    width: 80%;
    flex-direction: row;
    flex: 1;
}

.WFDiv img,
.SimpleSoftRight img {
    width: 100%;
}

.WFDiv {
    width: 40%;
    margin-bottom: 5%;
}

.UpComingSect1 {
    width: 70%;
    display: flex;
    flex-direction: row;
}

.WFDiv p {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.463);
}

.WhatFeatureContent p {
    color: black;
    font-weight: normal;

}

.SimpleSoftContent {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SimpleSoft {

    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
    justify-content: center;

}

.SimpleSoftLeft {
    font-weight: bolder;
    width: 40%;
}

.SimpleSoftLeft p {
    color: black;
    font-weight: normal;
}

.TotSimpleSoft span p {
    font-size: 10px;
    color: black;
}

.TotSimpleSoft span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TotSimpleSoft {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: space-between;
}

.SimpleSoftRight {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.WFSpan {
    color: #0C5899;

}

/*  About US */
.HeaderImg {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.HeaderImg img {
    width: 70%;
    height: 70%;
}

.ABSimpleSoft {
    padding: 40px 0px 40px 0px;
    background: #4e87d617 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ABSimpleSoftRight {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.ABSimpleSoftRight p {
    color: black;
}

.ABSimpleSoft h2 {
    font-weight: bold;
}

.CheckCircle {
    background-color: #0a3f7f;
    color: white;
    padding: 3px;
    border-radius: 100%;
    font-weight: bold;
}

.OurMsVs {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/*                   Service  */
.ServiceHead {
    display: flex;
    width: 80%;
    justify-content: space-between;
    /* align-items: end; */
  
}
.ServiceHead1 {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: end;
    margin-top: 50px;
  
}

.CardHeader {
    display: flex;
    justify-content: space-between;

}

.CardHeader span {
    font-size: 30px;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.112);
}

.ServiceSpan {
    color: #b8ef71;
}

.ServiceRight {
    flex: 60;
}

.ServiceLeft {
    display: flex;
    flex-direction: column;
    flex: 40;
    justify-content: center;
    margin-bottom: 10%;
}
.ServiceLeft1 {
    display: flex;
    flex-direction: column;
    flex: 40;
    justify-content: center;
    margin-bottom: 10%;
}

.ServiceRight img {
    width: 80%;
}

.ServiceHeadContent {
    width: 40%;
}

.BlogHeadr {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;

}

.BlogHeadrRight {
    display: flex;
    flex: 60;
    justify-content: space-evenly;
    height: 90%;
    align-items: center;
    flex-direction: column;
    color: black;
}
.BlogHeadrRight img{
    width: 430px;
    height: 300px;
    rotate: calc(-8deg);
    border-radius: 20px;

}
.ContactInform {
    
   width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

}

.ContactEmailInfo {
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    color: black;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 4px 8px 18px #00000024;
}
.ContactEmailInfoSpan{
    font-size: 10px;
    color: #0a3f7f;
}
.ContactEmailInfo p {
    color: black;
    font-weight: normal;
}

.BlogCardContentContainer {
    margin-top: 20%;
}

.CardContentContainerinfor {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70%;
    color: #0a3f7f;
    cursor: pointer;

}

.ContactHeadImg{
    width: 550px;
    height: 350px;
    min-width: 550px;
    min-height: 350px;
    border-radius: 20px;
    rotate: calc(-2deg)
}

/* margin-top: 50px;
width: 1000px ; 
min-width: 350px;
max-width: 1700px;
height: 1000px ;
min-height: 350px;
max-height: 1700px;
margin-bottom: 20px; */
/* width: 550px;
height: 350px;
min-width: 550px;
min-height: 350px;
border-radius: 20px; */

.blogBtn {
    background: transparent linear-gradient(180deg, #1A5298 0%, #0B3467 100%) 0% 0% no-repeat padding-box;
    padding: 15px;
    margin: 10px 0px 10px 0px;
    border-radius: 10px;
    border: none;
    color: white;
    bottom: 0;
}

.BlogImge {
    width: 250px;
    height: 200px;
    min-width: 250px;
    min-height: 200px;
    border-radius: 20px;
    margin: 10px 0px 10px 0px;
}

.ServiceContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ContactHeadImg {
    width: 60% !important;
}

.ContactUsForm {
    color: black;
    padding: 10px;
    border-radius: 20px;
    margin-top: 10px;
    padding: 30px;
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 4px 8px 18px #00000024;
}

.formcontentArea {
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.formcontentButton {

    width: 100%;
    background: #97CC4F;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    color: white;
    padding: 10px
}

.ContactUsName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.formcontent {
    width: 100%;
}


/*  footer  */

.footer {


    background-color: #0a3f7f;
    height: 500px;
   
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.footerIcons {
    width: 70%;

}

.footerIconsContent {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.LeftfooterIcons {
    width: 40%;

    color: white;
    display: flex;
    flex-direction: column;
}

.LeftImgIconFoot {

    color: #7AA146;
    background-color: rgba(255, 255, 255, 0.108);
    padding: 10px;
    border-radius: 100%;
    font-size: 40px !important;
    margin-right: 10px;
}

.LeftfooterIcons p {

    margin-top: 20px;
    color: white;

}

.iframe {
    width: 70%;
    height: 100vh;
    border: none;
    margin-top: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

.HeaderImgIc {
    margin-top: 50px;
    width: 500px;
    min-width: 200px;
    max-width: 700px;
    min-height: 200px;
    max-height: 700px;
    height: 500px;
    margin-bottom: 20px;

}

.HeaderImgIc1 {
    margin-top: 50px;
    width: 1000px;
    min-width: 350px;
    max-width: 1700px;
    height: 1000px;
    min-height: 350px;
    max-height: 1700px;
    margin-bottom: 20px;
}

.RightfooterIcons {
    height: 100%;
    width: 50%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.RightfooterContent {
    width: 70%;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.Rightfooterinput {
    background-color: white;
    height: 3%;
    border-radius: 10px;

}

.TermsCondi {
    background-color: white;
    width: 80%;
    padding: 20px;
    border-radius: 30px;
}

.TermsCondiContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
}

.CardContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;


}

.TermsCondiContent p {
    color: black;
    margin: 10px;

}

.RightfooterBtn {
    background-color: #97CC4F;
    height: 50px;
    margin-left: 20px;
    padding: 10px;
    border: 0;
    border-radius: 10px;
    color: white;
}

.Rightfootertabs {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    margin-top: 30px;
}
.RightfootertabsContent{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: end;

    width: 100%;
   
}
.BlogHeadrRightImg{
    width: 500px !important;
    rotate: -0deg !important;
    height: 400px !important;
    /* width: 600px;
    min-width: 300px;
    max-width: 1000px;
    
    min-height: 200px;
    max-height: 1000px; */
}

.Rightfootertabsb {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: end;
    width: 60%;
   


}

.RightfootertabsContent p,
.Rightfootertabsb p {
    cursor: pointer;
    /* margin: 10px; */
}

.RightfootertabsContent p:hover,
.Rightfootertabsb p:hover {
    text-decoration: underline;
    
}

.RightfooterIconsb {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    cursor: pointer;
}

.RightfooterIconsU {
    background-color: #7AA146;
    color: white;
    padding: 10px;
    font-size: 40px !important;
    border-radius: 100%;
}

.footercopyRight {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgba(255, 255, 255, 0.327);
    background-color: #0a3f7f;
    width: 100%;
   
}

.toggleButton {
    display: none;
}

.toggleButton1 {
    display: block;
    background-color: #7AA146;
    color: white;
    padding-left: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 20px;
}

.LeftWFContent {
    width: 100%;
}

/* Extra small devices (phones, 600px and down) */

@media (max-width:600px) {
    .SimpleSoftContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .ABSimpleSoftRight {
        width: 90%;
    }

    .SimpleSoftLeft {
        width: 80%;
    }
    .Rightfootertabs{
        display: flex;
        flex-direction: column;
    }
    .BlogHeadrRight img{
        width: 300px;
        height: 300px;
        rotate: calc(0deg);
        border-radius: 20px;
    
    }
}
@media only screen and (max-width: 568px) {
    .ServiceLeft1{
        margin-bottom: 70%;
    }
   
  
    .UpComingSect1{
        width: 100%;
       }
    .ServiceHeadContent{
        width: 70%;
      
    }
    .headerTxt {
        width: 100% !important;
        /* font-size: 1vw; */
        font-weight: 400;
        font-size: 23px;

    }
    .headerTxt1 {
        width: 100% !important;
        /* font-size: 1vw; */
        font-weight: 400;
        font-size: 18px;

    }
    .CardDesignBlog{
        width: 270px;
    }
    .Rightfootertabsb{
        width: 110%;
    }
}

@media only screen and (max-width: 768px) {
    .HeaderImgIc {
        margin-top: 50px;
        width: 270px;
        min-width: 200px;
        max-width: 700px;
        min-height: 200px;
        max-height: 700px;
        height: 300px;
        margin-bottom: 60px;

    }
   
    .OfferContent{
        width: 100%;
    }

    .activeImg {
        width: 100%;
        height: 140%;
        margin-top: 20px;

    }

  
   .UpComingSect1{
    width: 90%;
   }

    .RightUpcome {
        margin-top: 30px;
        width: 100%;
         
       
       

    }

    
    .HamburgderContent{
        display: block;
    }

    .bkTop {
        /* height: 60vh; */
      

    }

    .CardContent {
        grid-template-columns: repeat(2, 2fr);

    }

    .CardContent1 {
        grid-template-columns: repeat(2, 3fr);


    }


    .HomeContainer {
        width: 100%;

    }



    .navbar {
        display: none;

        /* overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        margin: 40px 0px 10px 0px; */
    }

    .navbar ul {
        display: inline-block;
    }

    .navbar li {
        display: inline-block;
        margin-right: 10px;

    }

    .TopNavContent ul {
        display: inline-block;

    }

    .toggleButton {
        display: block;
        background-color: #7AA146;
        color: white;
        padding-left: 20px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 20px;
    }

    .WFDiv {
        width: 100%;
        flex: 1;
    }

    .WFDiv img {
        width: 100%;
    }

    .TopNavContent {
        width: 100%;
        display: flex;
        flex-direction: row;

    }

    .headerTxt {
        width: 100%;
        display: block;
    }
    .headerTxt1 {
        width: 100%;
        display: block;
    }
    .headerTxt span{
        display: block;
    }
    .headerTxt1 span{
        display: block;
    }



    .headerpara p {
        font-size: 12px ;
    }

    .headerTxt h1 {
        font-size: 23px ;
    }

    

    .navBar ul li span {
        display: none;
    }

    .activeImgContent img {
        width: 100%;
    }

    .UpComingSect1 {
        display: flex;
        flex-direction: column-reverse;

    }



    .RightWFContent,
    .LeftWFContent,
    .OfferPara,
    .LeftUpcome img,
    .ServiceHeadContent {
        width: 100%;
        flex:100;
        display: flex;
        flex-direction: column;
        
        
    }
  .startNowButton{
    width:block;
  }

    .LeftfooterIcons,
    .ServiceHead {
        display: flex;
        flex-direction: column;
        flex:100;
        
        width: 100%;
    }
    .ServiceHead1{
        display: flex;
        flex-direction: column;
        flex:100;
        
        width: 100%;
    }
    .ServiceHead1 img{

    }

    .ServiceRight img {
        display: block;
        width: 100%;
    }

    .ContactEmailInfo {
        margin-top: 20px;
       
    }

    .LeftfooterIcons h1 {
        margin-top: 10px;
        font-size: 140%;
    }

    .RightfooterContent,

    .footerIcons {
        width: 100%;
    }

    .footerIconsContent {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    .footer {

        
        display: flex;
        flex-direction: column;
        height: 700px;
        width: 100%;
    }

    .RightfooterIcons {
        width: 100%;
    }

    .RightfooterIconsb {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .RightfooterIconsU {
        margin: 5px;
    }

    .TotSimpleSoft {
        width: 100%;
    }

    .LeftWFContent,
    .RightWFContent,
    .WhatFeatureContent {
        display: flex;
        flex: 1 !important;
        flex-direction: column;
        width: 100%;
    }

    .SimpleSoftRight {
        width: 100%;
    }

    

    

  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 500px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .Rightfootertabs {
        width: 80%;
    }

    .CardContent,
    .CardContent1 {
        grid-template-columns: repeat(2, 2fr);
        width: 100%;

    }
   


}

@media only screen and (max-width: 600px) {
.BlogHeadrRightImg{
    width: 400px !important;
    height: 400px !important;
}
    .CardContent,
    .CardContent1 {
        grid-template-columns: repeat(1, 2fr);
    }
.headButton{
    display: flex;
    /* flex-direction: column; */
}
.startNowButton{
    width: auto;
   
}
.headButton{
    width: 100%;
    display: flex;
    /* flex-direction: column; */
   padding: 10px;
    align-items: center;
    justify-content: center;
  
}
.ContactHeadImg{
    display: none;
}

    .headerpara p {
        width: 100%;
    }
    .ContactInform {
        display: flex;
        flex-direction: column;
        width: 100% ;
      
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .ServiceHead1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bkTop {
        /* height: 100vh; */

    } .headerTxt{
        width: 80%;
    }
    .headerTxt1{
        width: 80%;
    }
  
    .ContactHeadImg{
        display: none;
    }
    .BlogHeadrRight {
        display: flex;
        flex: 60;
        justify-content: space-between;
        height: 90%;
        align-items: center;
        flex-direction: column;
    }  
    .HeaderImgIc {
        margin-top: 50px;
        width: 270px;
        min-width: 200px;
        max-width: 700px;
        min-height: 300px;
        max-height: 700px;
        height: 500px;
        margin-bottom: 60px;

    }  
    .BlogHeadr {
        display: flex;
        flex-direction: column;
        width: 100%;

    }

    .RightfooterIcons {
        width: 90%;
    }

    .navBar {
        margin-top: 30px;
    }

    .navbar {

        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        margin: 40px 0px 10px 0px;
    }

    /* .startNowButton {
        width: 15%;
    } */

   

    .navbar ul {
        display: inline-block;
    }

    .navbar li {
        display: inline-block;
        margin-right: 10px;

    }

    .TopNavContent ul {
        display: inline-block;

    }

    .headerpara p {
        font-size: 10px;
    }

  

    .headerTxt {
        width: 100%;

    }
    .headerTxt1{
        width: 100%;
    }

    

    .letsBtn {
        margin-right: -10px;
        border-radius: 10px;
        padding: 10px;
    }

}

@media only screen and (min-width:792px) {
    .TopNavContent ul {
        width: 70%;
        margin-left: 100px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {

.HeaderImgIc {
        margin-top: 50px;
        width: 270px;
        min-width: 200px;
        max-width: 700px;
        min-height: 300px;
        max-height: 700px;
        height: 500px;
        margin-bottom: 80px;

    }  
   
    .navbar {

        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        margin: 40px 0px 10px 0px;
    }

    .navbar ul {
        display: inline-block;
    }

    .navbar li {
        display: inline-block;
        margin-right: 10px;

    }

    .TopNavContent ul {
        display: inline-block;

    }

    

    .headerpara p {
        font-size: 13px;
    }

    .headerTxt  {
        font-weight: 600; 
        /* font-size: 35px; */
    }

    .headerTxt1  {
        font-weight: 600; 
        /* font-size: 35px; */
    }
    .navBar ul li span {
        display: none;
    }

.ContactEmailInfo{
    margin: 10px;
}
   

    .footerIcons {
        width: 100%;
    }

    /* .footer {
        height: 90vh;
        min-height: 100%;
    } */

    .LeftfooterIcons h1 {
        font-size: 30px;
    }
}

@media only screen and (min-width: 1200px) {

    .RightfooterIcons {
        width: 70%;
    }
   .ContactEmailInfo{
    margin: 10px;
    /* width: 100%; */
    padding: 10px;
   }

    .CardContent {
        grid-template-columns: repeat(4, 2fr);
    }

    .CardContent1 {
        grid-template-columns: repeat(4, 3fr);
        margin: 0;
    }

    .CardDesign1 {
        height: 300px;
        width: 250px;
    }
    .HeaderImgIc {
      
        /* margin-bottom: 150px; */

    } 

    /* .footer {
        height: 80vh;
        min-height: 100%;
    } */

    .LeftfooterIcons {
        width: 40%;
    }
    .HeaderImgIc {
      
        margin-bottom: 60px;

    } 

    /**/
}
@media only screen and (max-width:400px){
    .BlogHeadrRightImg{
        width: 300px !important;
        height: 300px !important;
    }
}
@media only screen and (min-width: 1400px) {
    .bkTop {
        /* height: 100vh;
        max-height: 150vh; */
    }

    /* .footer {
        height: 100vh;
        min-height: 100%;
    } */
    .HeaderImgIc {
      
        margin-bottom: 60px;

    } 

}
@media only screen and (min-width: 1600px) {
    .HeaderImgIc {
      
        margin-bottom: 150px;

    } 
}