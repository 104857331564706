.UserProfile{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.UserProfile p span{
    color:black;
    margin-left: 20px;
}
.UserProfile h6{
    
    font-size: 25px;
}

.UserData{
display: flex;
flex-direction: column;


}
.UserData p{
   color: black;
   margin-left: 20px;
}
.UserDataheadContent{
    display: flex;
    flex-direction: row;
    align-items: center;
 
}
.webinarContent{
    width: 70%;
}
.category p{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.category p span{
    background-color: #7aa146;
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.559);
    padding: 5px 15px 5px 15px;
    align-items: center;
  
}
.category1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.MyReview{
    display: flex;
    flex-direction: column;
    flex:20;
    width: 100%;
    box-shadow: 0px 7px 24px #00000034;
    padding: 20px;
    border-radius: 10px ;
    margin-top: 30px;
    justify-content: center;
}
.flexDist p{
color: #1C74BC;
font-weight: light;
font-size: 20px;
}
.MyReviewContent{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.commentPara{
    color: grey;
    margin-top: 10px;
    
}
.programImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;

}
.flexDist{
    display: flex;
    flex-direction: column;
}
.flexDist p{

}
.editIcon{
    color: #1C74BC;
    cursor: pointer;
}
.MyReview p{
    font-size: 13px;
}