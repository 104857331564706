/* Container styles */
.container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #fff;
}

/* Unity container */
.unity-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.unity-canvas {
  width: 100%;
  height: 100%;
  border: none;
}

/* Loading overlay */
.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem 2rem;
  border-radius: 10px;
  text-align: center;
}

/* Form container */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 400px;
}

/* Input styles */
.form-input {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Button styles */
.form-button {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.2rem;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #357ab8;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-container {
    padding: 1.5rem;
    width: 90%;
  }

  .form-input {
    font-size: 0.9rem;
  }

  .form-button {
    font-size: 1rem;
  }
}
