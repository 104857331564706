.ChatContainer {
    width: 100%;
    margin: 20px;
    height: 100%;
}

.Chatparts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;


}

.ChatpartsLeft {
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex: 30;
    flex-direction: column;
}

.ChatpartsRight {
    min-height: 70vh;
    height: 100%;

    width: 100%;
    display: flex;
    flex: 70;
    justify-content: flex-end;
    flex-direction: column;
    justify-content: space-between;


}

.chapterHead {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.314);

}

.chattinghead {
    display: flex;
    /* justify-content: center; */
    align-items: center;

}

.chattinghead p {
    color: black;
    font-size: 20px;
}

.Chathead {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Chathead h4 {
    font-weight: bold;
}

.Chathead button {
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border: none;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    color: white;
    outline: none;
}

.UpgradeDiv {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.typoDesc {
    width: auto;
    min-width: 400px;
    padding: 10px;
}

.BoxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: white;

    box-shadow: 24;
    padding: 20px 20px 20px 20px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
}

.HandleCloseIcon {
    font-size: 40px !important;
    padding: 4px;
    background-image: linear-gradient(to bottom, #174C8E, #184E92, #154888, #13437F, #174C8E);
    border: 4px solid white;
    color: white;
    border-radius: 100%;
}

.BoxStyle span {
    cursor: pointer;
    position: absolute;
    margin-top: -10px;
    margin-right: -20px;
    outline: none;
    border: none;
    width: 10%;
    top: 0;
    float: right;
    right: 0;

}

.Inputfield {
    width: 100%;
    border-radius: 20px;
}

.InputTextfield {
    width: 100%;
    margin-left: 2px;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.516);
}

.InputTextfield:focus {
    border: none;
    outline: 1px solid rgba(0, 0, 0, 0.516);
}

.TypoButton {
    width: 100%;
    font-weight: bold;
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border-radius: 10px !important;
    border: none;
    color: white !important;
    padding: 10px;
    margin-bottom: 10px;
    outline: none;
    text-align: center;
    cursor: pointer;
}

.TicketDesc {
    margin: 10px;
    box-shadow: 0px 7px 24px #00000014;
    background-color: #00000014;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.TicketDesc p {
    color: black;
}

.TicketDesc span {
    color: rgba(0, 0, 0, 0.378);
    font-size: 15px;
}

.SendMsgButton {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.373);
    border-radius: 10px;
}

/* .DisplayMsg{
    width: 100%;    ;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

} */

/* .DisplayMsg p{
    color: black;
    background-color: rgba(128, 128, 128, 0.195);
    padding: 10px;
    border-radius: 30px;
} */
.SendMsgButton button {

    background-color: white;
    border: none;
    color: #81ac48;
    outline: none;
    padding: 10px 0px 10px 0px;

}

.DisplayMsgContent {
    margin-top: 10px;
    width: 100%;
    min-height: 70vh;
    height: 70vh;

    display: flex;
    flex-direction: column;

    overflow-y: scroll;

}
.DisplayMsgContent1{
    margin-top: 10px;
    width: 100%;
    min-height: 70vh;
    height: 70vh;

    display: flex;
    flex-direction: column;

    justify-content: flex-end;
}
.DisplaycoachContent {
    margin-top: 10px;
    width: 100%;
    min-height: 70vh;
    height: 70vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;


}

.SendMsgButton input {
    width: 96%;
    border: none;
    margin-left: 3px;
}

.SendMsgButton input:focus {

    border: none;
    outline: none;
}

.DisplayMsg {
    width: 100%;
    ;
    display: flex;
    flex-direction: column;
    justify-content: end;


}

.msgTextLeft p {
    color: black;
    background-color: rgba(128, 128, 128, 0.195);
    padding: 10px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    max-width: 400px;
}
.msgTextRight p {
    color: black;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    max-width: 400px;
}
.SendMsgButton button {
    background-color: white;
    border: none;
    color: #81ac48;
    outline: none;
    padding: 10px 0px 10px 0px;

}

.SendMsgButton input {
    width: 96%;
    border: none;
    margin-left: 3px;
}
.LoadMoreButton{
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    width: 30%;
    text-align: center;
   

}
button{
    outline: none !important;
}
.SendMsgButton input:focus {

    border: none;
    outline: none;
}

.msgTextLeft {
    display: flex;
    
    width: auto;
    justify-content: flex-end;
    align-items: center;
    right: 0;
    bottom: 0;
}
.msgTextLeft span{
    font-size: 13px;
    color: gray;
    margin-right: 6px;
}
.msgTextLeft p {
    width: auto;
    bottom: 0;
}

.msgTextRight {
    display: flex;
    align-items: center;
    width: auto;
    justify-content: flex-start;

    right: 0;
    bottom: 0;
}
.msgTextRight span{
    font-size: 13px;
    color: gray;
    margin-left: 6px;
}

.msgTextRight p {
    width: auto;
}

.CoachImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
}

.CoachDesc {
    display: flex;
    flex-direction: column;
}
@media screen and (max-width:1000px) {
    .CoachDesc p,span{
display: none;
    }
    .TicketDesc {
        margin: 5px;
      
        border-radius: 20px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    
    }
    .DisplaycoachContent{
        width: 50%;
    }
    
}