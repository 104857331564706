
.maincontainer{
    margin: 20px 30px 30px 30px;
    min-height: 100vh;
}
.search{
    justify-content: center;
    align-items: center;
} 
.box1{
    height: 35px;
    width: 350px;
    padding: 25px 25px 25px 45px;
    border-radius: 8px; 
    background: url(../../../../../public/images/svg/Coach/search.svg) 5% 49% no-repeat padding-box;
    background-size: 20px,20px;
    margin-bottom: 40px;
}

.curvedbordertable table, th, td{
    height: 45px;
    width: 900px;
    margin: 10px;
    border: 0.5px;
    border-bottom: 1px solid white ;
    border-top: 1px solid white; 
    border-left: 1px solid white;
    border-collapse: collapse;
    text-align: center;
    background-color: white;
    font-size: 14px;
    cursor: pointer;
    padding: 8px;
    white-space: nowrap;
}
.curvedbordertable table, th{
   background-color: #F1F1F5; 
   color: #B5B5BE;
 
}
table {
    margin: 20px;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.curvedbordertable {
    border-radius: 8px;
    overflow: scroll;
}
.maindiv{
    display: flex;
    justify-content: center;
}
.td1{
   margin-left: 40px;
   display: flex;
   flex-direction: row;
   align-items: center;
   width: auto;
   height: 1px;
  }

.text{
    margin-left: 15px;
    bottom: 5px;
}
.tab2{
   margin-left: 40px;
   display: flex;
   flex-direction: row;
   align-items: center;
   width: auto;
   height: 1px;
}
.tabb2{
    margin-left: 10px;
    bottom: 5px;
  }
  .tab3{
   margin-left: 30px;
   display: flex;
   flex-direction: row;
   align-items: center;
   width: auto;
   height: 1px;
  }
  .tabb3{
    margin-left: 10px;
    bottom: 5px;
  }
  .text1{
    margin-left: 10px;
    bottom: 5px;
    color: #7AA146;
  }
  .td2{
    margin-left: 60px;
    display: flex;
    width: 70px;
    height: 1px;  
  } 
  .text2{
    margin-left: 10px;
    bottom: 5px;
    color: #FFBA07;
  }
  .td4{
    margin-left: 60px;
    display: flex;
    width: 70px;
    height: 1px;
   }
   .tdc{
    margin-left: 30px;
    display: flex;
    width: 70px;
    height: 1px; 
   }
   /* .complete{
        margin-left: 10px;
        bottom: 5px;
        color: #7AA146;
   } */
.td3{
    color: #1572e4;
}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7AA146; 
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
    width: 160px;
    margin-left: 40px;
}
.mark{
    padding: 4px;
}
/* GE-Tab3 */
.comment{
    color: #1572e4;
    text-decoration-line: underline;
}
.com{
    color: #7AA146;
}
/* table, th, td{ */
@media screen and (max-width: 600px) {
   
}