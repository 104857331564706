.cnt{
    width: 100%;
    max-width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imagedivv{
     justify-content: center;
     display: flex;
     align-items: center;
     width: 50%;
     margin-bottom: 35px;
     margin-top: 10px;
     
 }

    .profilediv{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        
    }
 .profilediv h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #171725;
}
.sharedivv{
    display: flex;
    align-items: center;
    justify-content: center;
}
 .sharedivv p{
    width: 80%;
    text-align: center;
     letter-spacing: 0.06px;
 }

 .button{
 width: 100%;
 height: 50px;
 background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
 border-radius: 10px;
 opacity: 1;
 text-align: center;
color: #FFFFFF;
 border: none;
 }
 .butttondiv{
    margin-top: 40px;
    width: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
 }
