.setcontainer {
  height: 100%;
  min-width: 100%;
  width: auto;
  overflow-y: scroll;
  padding: 20px;
}

.mainsetcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.setleftcontainer {
  display: flex;
  flex: 25;
}

.setrightcontainer {
  display: flex;
  flex: 75;
}
.muiInput{
  width: 100%;
}
.multiFmIcon {
  position: absolute;
  float: right;
  right: 0;
  top: 0;
  margin-top: -10px;
  margin-right: -10px;
  padding: 2px;
  color: white;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
}
.leftInputData {
  width: auto;
  display: flex;
  flex-direction: row;
}
.MainImgPre1{
  width: 150px;
  height: 150px;
}
.IconImg{
  width: 100px;
  height: 100px;
}
.InputDataContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin-top: 20px;
}

.setcontainer ul {
  width: 100%;
}

.setheader {
  display: flex;
  margin-left: 10px;
  font-size: 25px;
  /* background-color: red; */
  font-weight: bold;
}

.setsidebar {
  height: 100vh;
  width: 250px;
  border-right: 1px solid rgba(128, 128, 128, 0.623);
}

.setsidebar ul li {
  list-style-type: none;
  text-transform: capitalize;
  cursor: pointer;
  margin: 0px 0px 30px 0px;
  color: grey;
}

.Inputfield {
  width: auto;
  position: relative;
}

.Addbtn .AddbtnContainer {
  margin: 20px;
  font-size: 30px;
  color: white;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
}
.setli {
  display: flex;
  position: relative;
  align-items: center;
}

.setli img {
  padding: 10px;
}

.setliactive {
  color: black;
  font-weight: bold;
}

.setliactive img {
  padding: 10px;
}

.setliactive::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  margin-right: -3px;
  border-radius: 8px;
  background-color: #f89c72;
}

/*               tab1  CSS                         */
.tabcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.tab1img {
  display: flex;
  position: relative;
  margin-bottom: 30px;
  width: 20%;
}

.tab1img1 {
  width: 150px;
}

.tab1img2 {
  position: absolute;
  width: fit-content;
  height: fit-content;
  bottom: -1rem;
  transform: translateX(120%);
}

.tab1formcontainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.certificateDetails{
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  width: fit-content;
}
.leftab1formcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.rightab1formcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.SelectLocat {
  width: 80%;
  padding-top: 10px;
  margin-top: 10px;
  background-color: #93c65011;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.242);
  outline: none;
}

.SelectLocatLabel {
  font-size: 10px;
  margin-top: -40px;
  color: #388dd6;
}

.SelectLoc {
  margin-left: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.158);
}

.leftab1formcontainer input {
  padding: 10px;
  margin: 5px;
  width: auto;
}

.rightab1formcontainer input {
  padding: 10px;
  margin: 5px;

  width: auto;
}
.mycalendar {
  width: 80%; /* Set the width of the calendar */
  max-width: 200px; /* Optional: set a maximum width */
  background-color: red;
}
.formbutton button {
  width: 60%;
  margin: 20px 0px 20px 0px;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 10px !important;
  border: none;
  color: white;
  padding: 10px;
  outline: none;
}

.button {
  width: 100%;
  margin: 20px 0px 20px 0px;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 10px !important;
  border: none;
  color: white;
  padding: 10px;
  outline: none;
}

.InputfieldId:focus {
  border-bottom: 1px solid black;
  outline: none;
}

.InputfieldId {
  border: none;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid black;
}

.selectag {
  padding: 10px;
  margin: 5px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.212) !important;
  border-radius: 10px;
}

/*  YesNo      */

.tabheader {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 30px;
}

.tabformcontent {
  display: flex;
  justify-content: space-between;
}

.leftabcontent {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.rightabcontent {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.leftabcontent header {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}

.rightabcontent header {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}

.choicetag {
  display: flex;
}

.choicetag label {
  padding: 10px;
}

.inputtab2 {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: space-between;
}

.inputtab2 input {
  padding: 10px;
  margin: 5px;
  width: 96%;
}

.inputtab3 {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: space-between;
}

.inputtab3 input {
  padding: 10px;
  margin: 5px;
  width: 100%;
}

.YesNo {
  margin: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

/* forminput4 */
/*       tab 4 */
.tab4container {
  display: flex;
  flex-direction: column;
}

.tab4container textarea {
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  border-color: grey;
  resize: none;
  width: 100%;
  height: 150px;
}

.changepwdhead {
  color: grey;
}

.forminput4 {
  width: 60%;
}

.forminput4 .TextField {
  width: 100%;
}

.togglenotify {
  display: flex;
  width: 60%;
  justify-content: space-between;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #97cc4f;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #97cc4f;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}

.withdrawcontainer {
  display: flex;
  width: 50%;
  position: relative;
}

.Withdrawcheck img {
  width: 100px;
  height: 50px;
  margin-right: 20px;
  padding: 2px;
}

.Withdrawcheck1 img {
  margin: 10px 10px 10px 10px;
  padding: 5px;
  width: 70px;
  height: 30px;
}

.inputtab7 {
  width: 60%;
}

.input7tab {
  width: 100%;
}

.inputtext:focus {
  border: 1px solid rgb(40, 161, 208) !important;
  outline: none;
}

.inputtext:focus::placeholder,
.inputtext:placeholder-shown {
  color: transparent;
}

.inputlabel {
  position: relative;
  width: max-content;
  left: 10px;
  margin-top: -60px;
  margin-bottom: 40px;

  pointer-events: none;
  font-size: 12px;
  display: none;
}

.inputtext:focus + .inputlabel {
  display: block;
  color: rgb(40, 161, 208);
  background-color: white;

  transition: all 0.3s ease-in-out;
}

/* leftab1formcontainer */

.passwordlabel {
  position: relative;
  width: max-content;
  left: 10px;
  margin-top: -60px;
  margin-bottom: 40px;
  pointer-events: none;
  font-size: 12px;
  display: none;
}

.passwordcss {
  border-bottom: 1px solid grey !important;
}

.passwordcss:focus + .passwordlabel {
  display: block;
  color: grey;
  background-color: white;

  transition: all 0.3s ease-in-out;
}

.passwordcss:focus::placeholder,
.passwordcss:placeholder-shown {
  color: transparent;
}

.HeadContainer {
  display: flex;
  justify-content: space-between;
}

.calendarstyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: white;
  margin: 10px;
  border: none !important;

  box-shadow: 0px 10px 20px #0000000d;
  margin-bottom: 20px;
}

.nextprevbtn {
  width: 70px;
  height: 70px;
}

.tilecontent:focus {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 100%;
}

.tilecontent:hover {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 100%;
}

.tilecontent:active {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 50%;
}

.tilecontent:after {
  content: "";

  border-radius: 50%;
}

.custom-calendar.rmdp-wrapper,
.calendarbtn button {
  width: 100%;

  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 10px !important;
  border: none;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  outline: none;
}

.setavailablebtn button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #97cc4f;
  background-color: white;
  color: #88b64b;
  outline: none;
}

.YesNo[type="checkbox"] {
  accent-color: green;
  color: white !important;
}

.YesNo input[type="checkbox"]::after {
  background-color: white !important;
  color: white !important;
}

.calendarli {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calendarli img {
  width: 40px;
}

.calendarli label {
  position: relative;
}

.muiInput {
  border-radius: 10px !important;
}

.typeDetailCnt {
  margin: 30px 0 0 0;
}

.calendartile {
  margin-bottom: 20px;
}

.BoxStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  background-color: white;

  box-shadow: 24;
  padding: 20px 30px 10px 20px;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}

.HandleCloseIcon {
  font-size: 40px !important;
  padding: 4px;
  background-image: linear-gradient(
    to bottom,
    #174c8e,
    #184e92,
    #154888,
    #13437f,
    #174c8e
  );
  border: 4px solid white;
  color: white;
  border-radius: 100%;
}

.BoxStyle span {
  cursor: pointer;
  position: absolute;
  margin-top: -10px;
  margin-right: -10px;
  outline: none;
  border: none;
  width: 10%;

  top: 0;
  float: right;
  right: 0;
}

.typoDesc {
  width: 100%;
}

.TypoForm {
  width: 100%;
  display: flex;

  flex-direction: column;
}

.TypoButton {
  width: 100%;
  font-weight: bold;
  text-transform: capitalize !important;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 10px !important;
  border: none;
  color: white !important;
  padding: 10px;
  margin-bottom: 20px;
  outline: none;
}

.sideCal {
  display: flex;
  color: grey;
}

.sideCal1 {
  margin-right: 10px;
}

.eventslot {
  cursor: pointer;
  background-color: white;
  outline: none !important;
  border: none;
  margin: 10px 0px 40px 0px;
}

.eventSlotIcon {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  color: white;
  border-radius: 100%;
}

.eventslot span {
  margin-left: 10px;
  font-weight: bold;
}

.AddSlot {
  padding: 10px;
  margin: 0px 10px 10px 10px;
  border-radius: 10px;
  background-color: #98cc4f35;
}

.handlClickSave {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  color: white !important;
  text-transform: capitalize;
  margin: 10px 0px 10px 0px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
}

.timeContainer {
  display: flex;
  flex-direction: column;
}

.timeContainer {
  display: flex;
}

.setAvailable {
  display: flex;
  width: 100%;
}

.timeContainer {
  display: flex !important;
  width: 100%;
  justify-content: space-between !important;
}

.AddSlot {
  display: flex;
  justify-content: space-between !important;
}

.deleteSlot button {
  background-color: none;
  border-radius: 100%;
  padding: 2px;
  border: 0;
  outline: none;
}

.Totime {
  margin: 0px 10px 0px 10px;
}

/*               Set Tab 2                 */

.setTab2 {
  width: 100%;
  height: 100%;
  margin-left: 20px;
}

.setTabHeader h4 {
  margin-bottom: 20px;
}

.CardContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  width: 900px;
}

.CardAddButton {
  flex: 0 0 50px;
  display: flex;
  color: white;
  height: 200px;
  width: 70px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: black;
  cursor: pointer;
}

.displayCard {
  flex: 0 0 350px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  margin: 0px 20px 0px 20px;
  height: 200px;
  width: 350px !important;
  padding: 0px 20px 0px 20px;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  color: white;
  border-radius: 20px;
}

.displayCard img {
  width: 70px;
}

.cardEdit {
  position: relative;
}

.cardEdit p {
    position: absolute;
    color: red;
    border-radius: 100%;
    border: 20px solid white;
    padding: 40px;
    float: right;
    cursor: pointer;
    margin-top: -40px;
    margin-left: -52px;
    display: flex;

}

.cardEdit img {
  width: 15px;
  margin-right: 5px;
}

.CardContent {
  display: flex;

  flex-direction: column;
  justify-content: center;
}

.CardDesign {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stleCSV {
  display: flex;
  flex-direction: column;
}

.CardContent p {
  color: white;
}

.transactionDetail {
  width: 90%;
  margin-top: 20px;
}

.transactionList {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.abc {
  display: none;
}

.transactionist {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.transactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin: 10px;
}

.transac p {
  color: black;
  font-size: 15px;
  font-weight: bold;
}

.transAmt p {
  color: #0c5899;
  font-weight: bold;
}

.NotifyType {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
}

.NotifyContainer {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.NotifyData {
  display: flex;
  flex-direction: column;
  margin: 15px 0px 15px 0px;
}

.NotifyData1 {
  display: flex;
  flex-direction: column;
}

.NotifyData p {
  color: black;
  font-size: 15px;
}

.tab5container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.tab5leftcontainer {
  display: flex;
  flex: 30;
  width: 100%;
  flex-direction: column;
}

.tab5rightcontainer {
  display: flex;
  flex: 70;
  margin-left: 10px;
  box-shadow: 0px 10px 20px #0000000d;
  padding: 20px;
}

.nextprevbtn {
  width: 70px;
  height: 70px;
}

.tilecontent:focus {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;

  border-radius: 100%;
}

.tilecontent:active {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;

  border-radius: 50%;
}

.tilecontent:after {
  content: "";
  background-color: red !important;
  border-radius: 50%;
}

.setavailablebtn button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #a7f33e;
  background-color: white;
  color: #88b64b;
}

.tab5rightcontainer {
  display: flex;
  flex-direction: column;
  flex: 80;
  width: 100%;
  margin-left: 10px;
  box-shadow: 0px 10px 20px #0000000d;
  padding: 20px;
  border-radius: 15px;
}
.HandleSaveAvail {
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  color: white !important;
  text-transform: capitalize;
  padding: 10px;
  border-radius: 10px;
  border: none;
  margin: 10px 0px 10px 0px !important;
  text-transform: capitalize !important;

  width: 100%;
}
.BigCalendar {
  border: 1px solid #7aa146;
  margin: 30px 10px 0px 0px;
  border-radius: 10px;
  padding: 5px;
}
.BigCalendar button {
  border-radius: 20px;
  margin: 5px;
  justify-content: center;
}
.BigCalendarContent {
  margin-left: -10px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.BigCalendarLeft {
  flex: 60;
  height: 90%;
  width: 100%;
}
.BigCalendarRight {
  flex: 30;
}

.setAvailContent ul li {
  list-style-type: none;
  margin: 10px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
}
.DateAndDay {
  background-color: #7aa14641;
  border: 1px solid #7aa146;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  width: 50px;
  color: #7aa146;
  border-radius: 10px;

  height: 60px;
}
.UpgradeDiv p {
  color: #13437f;
}
.DateAndDayPara {
  margin-left: 5px;
}
.DateAndDayPara p {
  margin-bottom: -20px;
  color: #0c5899;
  display: flex;
  flex-direction: row;
}
.DateAndDayPara p span {
  color: red;
  margin-left: 10px;
}
.setAvail h6 {
  font-weight: bold;
  font-size: large;
}
.calendDayTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.SelectTimeContent {
  display: flex;
  flex-direction: column;
}
.SelectTime {
  display: flex;
  flex-direction: row;
  /* width: 90%; */
  justify-content: space-between;
  min-width: 100px;
  height: 200px;
  max-height: 200px;
  overflow-y: scroll;
}
.SelectTime label {
  font-size: 13px;
}

.SelectTimeContent {
  display: flex;
  width: 100%;
  margin: 5px;
  flex-direction: column;
  justify-content: space-between;
}
.SelectTimeMainContent {
  display: flex;
  flex-direction: row;
}
.selectTimeLeft {
  display: flex;
  flex-direction: row;
}
.SelectTimeContent input {
  border: 1px solid #7aa146;
  padding: 5px;
  border-radius: 5px;
}
.availshow {
  color: black;
}
.availshowbtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}
.availshowbtnred {
  background-color: red;
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: white;
}
.availshowbtnblue {
  background-color: #13437f;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
}
.Availdatatime {
  color: #fff;
  width: 80%;
  background-color: #7aa146;
  padding: 10px;
  border-radius: 10px;
}
.addavlbtn {
  height: 29px;
  width: 28px;
  border-radius: 100%;
  color: white;
  border: none;
  justify-content: center;
  text-align: center;
  outline: none;
  background-color: red;
}
.addavlbtn1 {
  background-color: white;
  border: none;
  height: 29px;
  width: 28px;
  margin-top: 30px;
  margin-left: 30px;
  outline: none;
  border-radius: 100%;
}
.addTimeSlot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.addTimeSlot button {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  border-radius: 100%;
}
.addTimeSlotbtn {
  width: 35px !important;
  height: 35px !important;
  padding: 2px;
  border-radius: 100%;
  color: white;

  background-color: #13437f;
  border: 1px solid #13437f;
}

.Container {
  border: solid 2px gray;
  border-radius: 25px;
  padding: 10px;
  background-color: white;
}
.BankContainer_Header {
  display: flex;
  gap: 2rem;
}
.OutterContainer {
  border-radius: 25px;
  padding-bottom: 2px;
  width: 70%;
  background: #d7ebec;
  position: relative;
}
.bankFooter {
  display: flex;
  justify-content: safe;
  align-items: center;
}
.seperator{
  border: solid 1px gray;
}

/* 
.settingTitle {
  display: none;
} */

/* tab1formcontainer */
@media only screen and (max-width: 1068px) {
  .tab1formcontainer{
   flex-direction: column;
  }
  
  .setsidebar{
    width: 50px;
  }
  .setrightcontainer{
    width: 100%;
  }
}

@media screen and (max-width:942px) {
  .BigCalendarLeft{
    display: none;
  }
}