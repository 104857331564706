.AdminManage{
    margin: 20px;
}
.adminData{
    display:flex;
    flex-direction: row;
    
    justify-content: space-between;
}
.adminDataclr{
   color: #0B3269;
   font-size: 13px;
}
.adminDataIcon{
    color: #EF633E;
}
.adminDatacl{
    color: #436913;
}
.container2 {
  height: auto;
  width: 30%;
  border-radius: 10px;
  padding: 10px 10px 10px 45px;
  background: url(../../../../../public/images/svg/Coach/search.svg) 5% 57%
      no-repeat padding-box;
  background-size: 15px 15px;
  /* margin-bottom: 30px; */
}
.AdminManageHead button{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border: none;
    border-radius: 10px;
    padding:10px;
    color: white;
    outline: none;
}

.AdminManageHead{
    display: flex;
    flex-direction: row;
    min-width: 100%;
    width: 100%;
justify-content: space-between;
margin-bottom: 20px;
}
.delete{
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background: red;
    color: white;
    margin-right: 30px;
    outline: none;
}
.DontDelete{
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background: #0B3269;
    color: white;
    outline: none;

}
.Inputfield{
    padding: 5px;
}